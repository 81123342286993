<template>
    <div class="px-3 text-center">
        <img :src="`${locationURLOrigin}/public-assets/top-gafete`" alt="top-gafete" class="mx-auto"/>
        <div class="gafete-container mb-4 pb-5">
            <div class="image-row my-5">
                <img :src="`${locationURLOrigin}/public-assets/comex`" alt="comex"/>
            </div>
            <div class="title-row">
                <h3>Bienvenido</h3>
            </div>
            <template v-if="!emailSent">
                <div class="description-row mb-4 txt-muted">
                    Ingresa tus datos para acceder al evento
                </div>
                <div class="input-row">
                    <label for="email">Correo Electrónico</label>
                    <input type="email" id="email" placeholder="Ingresa tu email" v-model="email"/>
                </div>
                <div class="button-row mt-2 mb-5">
                    <button type="button" class="btn-primary" :class="{'disabled': !email }"
                            :disabled="!email || loading" @click.prevent="submit">Continuar
                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only"></span>
                        </span>
                    </button>
                </div>
            </template>
            <template v-else>
                <div class="description-row mt-4 txt-muted">
                    Hemos enviado tu enlace de acceso a:
                </div>
                <div class="description-row mb-4 txt-muted" style="color: #553283;">
                    <strong>{{ email }}</strong>
                </div>
                <div class="description-row txt-muted">
                    ¿El correo no es correcto?
                </div>
                <button class="btn btn-link" @click="emailSent = false">Ingrésalo de nuevo</button>
            </template>
        </div>
    </div>
    <div v-if="!closeRegisters" class="px-3">
        <div class="gafete-container mb-5 pb-3">
            <div class="title-alert">
                <div class="title-alert-img">
                    <img :src="`${locationURLOrigin}/public-assets/warning`" alt="alerta"/>
                </div>
                <div class="title-alert-titles">
                    <h3>¡Atención!</h3>
                    <h4>El cierre de registro se acerca</h4>
                </div>
            </div>
            <p>
                Recuerda que el registro para el Congreso de Vendedores cierra el <strong>2 de octubre</strong>.
                Asegúrate de completar tu información lo antes posible para no perder la oportunidad de vivir esta gran
                experiencia.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['locale', '$axios'],
    props: {closeRegisters: Boolean},
    data: () => ({
        loading: false,
        email: '',
        emailSent: false,
        locationURLOrigin: window.location.origin,
    }),
    methods: {
        submit() {
            this.loading = true;
            this.$axios.post('/api/contact/verify-email', {
                data: {
                    email: this.email
                }
            }).then(response => {
                if (response.data.status === 404) {
                    this.$swal('Correo no encontrado', response.data.message, 'error');
                } else if (response.data.status === 200) {
                    this.sendMagicLink();
                    this.emailSent = true
                    this.loading = false;
                } else {
                    this.$swal('Error', 'Algo salio mal', 'error');
                }
            }).catch(error => {
                this.$swal('Error', 'Algo salio mal', 'error');
            }).finally(response => {
                this.loading = false;
            })
        },
        sendMagicLink() {
            let data = {
                email: this.email,
                redirectUrl: '/perfil'
            }

            this.$axios.post('/api/v1/login/send-magic-link', {
                data: data
            })
        }
    }
}
</script>

<style scoped lang="scss">
.btn-primary {
    min-width: 100%;
}

.title-alert {
    display: flex;
    color: #e57b39;
    font-weight: bold;
    padding-bottom: 10px;

    .title-alert-titles {
        padding-left: 20px;
    }

    .title-alert-img {
        width: 50px;
    }

    img {
        width: 50px;
    }

    h3 {
        font-size: 24px;
        padding-bottom: 5px;
    }

    h4 {
        font-size: 18px;
    }
}
</style>