// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>


// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
// import.meta.globEager('../../components/global/**/*_component.js')
// import.meta.globEager('../../components/home/**/*_component.js')

import App from '@components/home/HomeLogin.vue';
import {generateApplication} from '@helpers/generate_component.js';
import {generateAxiosInstance} from '@helpers/axios_provider.js';

const axios = generateAxiosInstance();

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

generateApplication(App, "#login-app", {
    injections: [{
        name: 'locale',
        module: typeof locale != "undefined" ? locale : 'es'
    }, {
        name: '$axios',
        module: axios
    }],
    props: {closeRegisters: settings.CloseRegisters.toLowerCase() === 'true'},
    packages: [VueSweetalert2]
})